import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { InitialState } from '../store/reducer';
import './bestSellers.scss';
import {
  calculateKpisTop20,
  sortBestSellerObjects,
  groupAndSortAll,
  BestSellerObj,
  mergeObjects,
  kpiObject,
  kpisTotals,
  createKpiObject
} from '../helper';
import { generatedKpis, SelectedFilters } from '../store/actions';
import BestSellerGrid from '../components/BestSellerGrid/BestSellerGrid';

type BestSellersProps = {
  bestSellerProducts: BestSellerObj[];
  bestSellerType: string;
  filteredBestSellerProducts: BestSellerObj[];
  kpiData: kpiObject;
  generateKpis: (kpis: kpiObject) => void;
  selectedFilters: SelectedFilters;
};

type KpiObject = {
  [key: string]: {
    totalSalesInEuro: number;
    totalSalesQuantity: number;
  };
} | null;

const BestSellers = ({
  bestSellerProducts,
  bestSellerType,
  filteredBestSellerProducts,
  generateKpis,
  selectedFilters
}: BestSellersProps) => {
  const sortedAndOrder = sortBestSellerObjects(filteredBestSellerProducts, bestSellerType);
  const bestSellersGrouped =
    selectedFilters.iconCO.length > 0
      ? groupAndSortAll(sortedAndOrder, 'Function', 'SalesEuro', true)
      : bestSellerType === 'Generic'
      ? groupAndSortAll(sortedAndOrder, 'Function', 'SalesEuro', false, 'OriginalEvent', 'Line')
      : groupAndSortAll(sortedAndOrder, 'Function', 'SalesEuro', true, 'OriginalEvent');

  useEffect(() => {
    const groupedAndSortedPdfProducts20 = groupAndSortAll(
      bestSellerProducts,
      'Function',
      'SalesEuro',
      true,
      'OriginalEvent'
    );
    const groupedAndSortedPdfProductsAll = groupAndSortAll(
      bestSellerProducts,
      'Function',
      'SalesEuro',
      false,
      'OriginalEvent'
    );

    const mergedProducts = mergeObjects(
      groupedAndSortedPdfProducts20,
      groupedAndSortedPdfProductsAll
    ) as { [key: string]: { top20: BestSellerObj[]; allProducts: BestSellerObj[] } };
    const kpiData: any = Object.entries(mergedProducts).reduce(
      (acc, [key, value]) => {
        const { totalVolume, totalSales, totalSalesPercent, totalVolumePercent } =
          calculateKpisTop20(value.allProducts, value.top20);
        acc[key] = {
          totalVolume,
          totalSales,
          totalSalesPercent,
          totalVolumePercent
        };
        return acc;
      },
      {} as {
        [key: string]: {
          totalVolume: string;
          totalSales: string;
          totalSalesPercent: string;
          totalVolumePercent: string;
        };
      }
    );
    generateKpis(kpiData);
  }, [bestSellerProducts, generateKpis]);

  useEffect(() => {
    generateKpis(createKpiObject(bestSellersGrouped, bestSellerProducts, selectedFilters, kpis));
  }, [bestSellerType]);

  const kpis = (() => {
    const kpiObj: KpiObject = {};

    Object.entries(bestSellersGrouped).forEach(([key, value]) => {
      const totalSalesInEuro = value.reduce((acc, item) => {
        return +item.SalesEuro + acc;
      }, 0);

      const totalSalesQuantity = value.reduce((acc, item) => {
        const qty = item.SalesQty || 0;
        return +qty + acc;
      }, 0);

      kpiObj[key] = {
        totalSalesInEuro,
        totalSalesQuantity
      };
    });

    return kpiObj;
  })();

  return (
    <div className="bestSellers-container">
      {Object.entries(bestSellersGrouped).map(([key, value]) => {
        const { salesValue, volume } = kpisTotals(
          bestSellerProducts,
          value[0].EventCode,
          value[0].Function,
          selectedFilters
        );

        const totalSalesForSectionValue = +kpis[key].totalSalesInEuro.toFixed(1) || 0;
        const totalOverallSalesValue = +salesValue.toFixed(1);
        const percentValueOfSection = +(
          (totalSalesForSectionValue / totalOverallSalesValue) *
          100
        ).toFixed(1);

        const totalQtyForSectionVolume = +kpis[key].totalSalesQuantity || 0;
        const totalOverallQtyVolume = +volume;
        const percentVolumeOfSectionToTotal = +(
          (totalQtyForSectionVolume / totalOverallQtyVolume) *
          100
        ).toFixed(1);

        return (
          <section className="bestSellers-container__category" key={`${key}__${bestSellerType}`}>
            <Grid className="bestSellers-container__grid">
              <Grid xs={6} md={6} item>
                <h2 className="bestSellers-container__heading">{key}</h2>
              </Grid>
              <Grid xs={6} md={6} item>
                <ul className="bestSellers-container__kpiContainer">
                  <li className="bestSellers-container__kpi">
                    <span>
                      {bestSellerType === 'Generic' ? 'Value ' : 'Top 20 Value '}
                      <b>€{totalSalesForSectionValue}K </b>
                      {bestSellerType !== 'Generic' && (
                        <b>{` ${percentValueOfSection}% of Total`}</b>
                      )}
                    </span>
                  </li>
                  <li className="bestSellers-container__kpi">
                    <span>
                      {bestSellerType === 'Generic' ? 'Volume ' : 'Top 20 Volume '}
                      <b> {totalQtyForSectionVolume} </b>
                      {bestSellerType !== 'Generic' && (
                        <b>{` ${percentVolumeOfSectionToTotal}% of Total`}</b>
                      )}
                    </span>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <BestSellerGrid value={value} />
          </section>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: InitialState) => ({
  bestSellerProducts: state.bestSellerProducts,
  filteredBestSellerProducts: state.filteredBestSellerProducts,
  bestSellerType: state.bestSellerType,
  kpiData: state.kpis,
  selectedFilters: state.selectedFilters
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  generateKpis: (kpis: kpiObject) => {
    dispatch(generatedKpis(kpis));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BestSellers);
