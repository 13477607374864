import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InitialState } from '../../store/reducer';
import './filterAccordians.scss';
import { connect } from 'react-redux';
import { SelectedFilters, updateFilters, updateAvailableEventPDFs } from '../../store/actions';
import {
  BestSellerObj,
  getUniqueValues,
  eventFunctionDict,
  sortSeasonsByMostRecentFirst
} from '../../helper';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

type FilterEventTarget = EventTarget & {
  dataset: { name: keyof SelectedFilters; value: string };
};

type FilterAccordiansType = {
  bestSellerProducts: BestSellerObj[];
  selectedFilters: SelectedFilters;
  updateFilters: any;
  updateEventPDFs: (availableEvents: string[]) => void;
};

const FilterAccordians = ({
  bestSellerProducts,
  selectedFilters,
  updateFilters,
  updateEventPDFs
}: FilterAccordiansType) => {
  const getFunctions = (eventArray: string[], season: any): string[] => {
    const dataFiltered: BestSellerObj[] =
      season === 'All Seasons'
        ? bestSellerProducts
        : selectedFilters.iconCO.includes('Icon') && selectedFilters.iconCO.includes('MCO')
        ? bestSellerProducts.filter(
            (obj: BestSellerObj) => obj.Icon === 'Icon' || obj.MCO === 'MCO'
          )
        : selectedFilters.iconCO.includes('Icon')
        ? bestSellerProducts.filter((obj: BestSellerObj) => obj.Icon === 'Icon')
        : selectedFilters.iconCO.includes('MCO')
        ? bestSellerProducts.filter((obj: BestSellerObj) => obj.MCO === 'MCO')
        : bestSellerProducts.filter((obj: BestSellerObj) => obj.OriginalBornSeason === season);
    const filterFunctions: string[] = !eventArray.length
      ? getUniqueValues(dataFiltered, 'Function', updateEventPDFs)
      : [...new Set(eventArray.map((item) => eventFunctionDict(dataFiltered)[item].flat()).flat())];

    const functionOrders: Record<string, number> = {};
    dataFiltered.forEach((obj: BestSellerObj) => {
      functionOrders[obj.Function] = Number(obj.FunctionOrder);
    });

    filterFunctions.sort((a: string, b: string) => functionOrders[a] - functionOrders[b]);

    return filterFunctions;
  };

  const seasons = sortSeasonsByMostRecentFirst(
    getUniqueValues(bestSellerProducts, 'OriginalBornSeason', updateEventPDFs)
  );
  const events = getUniqueValues(
    bestSellerProducts,
    'OriginalEvent',
    updateEventPDFs,
    selectedFilters.season[0],
    selectedFilters
  );
  const functions = getFunctions(selectedFilters.event, selectedFilters.season[0]);
  const iconCOFilters = ['Icon', 'Carry Over'];

  const handleFilters = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const target = event.target as FilterEventTarget;
    const { name, value } = target.dataset;
    updateFilters(name, value);
  };

  const seasonFilterSelected = (item: string) => selectedFilters.season.includes(item);
  const eventFilterSelected = (item: string) => selectedFilters.event.includes(item);
  const functionFilterSelected = (item: string) => selectedFilters.functionCode.includes(item);
  const iconCOFilterSelected = (item: string) => selectedFilters.iconCO.includes(item);

  return (
    <div className="filter-accordian">
      <Accordion className="filter-accordian__container">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-accordian__summary">
          <Typography className="filter-accordian__category">Season</Typography>
        </AccordionSummary>
        <AccordionDetails className="filter-accordian__list">
          <li
            data-name="season"
            data-value={'All Seasons'}
            onClick={(e) => handleFilters(e)}
            className={
              seasonFilterSelected('All Seasons') || !selectedFilters.season.length
                ? 'filter-accordian__item selected'
                : 'filter-accordian__item'
            }>
            All Seasons
          </li>
          {seasons.map((item: string) => (
            <li
              data-name="season"
              data-value={item}
              onClick={(e) => handleFilters(e)}
              className={
                seasonFilterSelected(item)
                  ? 'filter-accordian__item selected'
                  : 'filter-accordian__item'
              }
              key={item}>
              {item}
            </li>
          ))}
          {seasons.length < 8 ? <div>still loading . . .</div> : ''}
        </AccordionDetails>
      </Accordion>
      <Accordion className="filter-accordian__container">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="filter-accordian__summary">
          <Typography className="filter-accordian__category">Icon and CO</Typography>
        </AccordionSummary>
        <AccordionDetails className="filter-accordian__list">
          {iconCOFilters.map((item: string) => (
            <li
              data-name="iconCO"
              data-value={item === 'Carry Over' ? 'MCO' : item}
              onClick={(e) => handleFilters(e)}
              className={
                iconCOFilterSelected(item === 'Carry Over' ? 'MCO' : item)
                  ? 'filter-accordian__item selected'
                  : 'filter-accordian__item'
              }
              key={item}>
              {item}
            </li>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion className="filter-accordian__container">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="filter-accordian__summary">
          <Typography className="filter-accordian__category">Event</Typography>
        </AccordionSummary>
        <AccordionDetails className="filter-accordian__list">
          {events.map((item: string) => (
            <li
              data-name="event"
              data-value={item}
              onClick={(e) => handleFilters(e)}
              className={
                eventFilterSelected(item)
                  ? 'filter-accordian__item selected'
                  : 'filter-accordian__item'
              }
              key={item}>
              {item}
            </li>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion className="filter-accordian__container">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className="filter-accordian__summary">
          <Typography className="filter-accordian__category">Function</Typography>
        </AccordionSummary>
        <AccordionDetails className="filter-accordian__list">
          {functions.map((item: string) => (
            <li
              data-name="functionCode"
              data-value={item}
              onClick={(e) => handleFilters(e)}
              className={
                functionFilterSelected(item)
                  ? 'filter-accordian__item selected'
                  : 'filter-accordian__item'
              }
              key={item}>
              {item}
            </li>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const mapStateToProps = (state: InitialState) => ({
  bestSellerProducts: state.bestSellerProducts,
  selectedFilters: state.selectedFilters
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  updateFilters: (name: keyof SelectedFilters, value: string) =>
    dispatch(updateFilters(name, value)),
  updateEventPDFs: (availableEvents: string[]) =>
    dispatch(updateAvailableEventPDFs(availableEvents))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterAccordians);
