import { ExpandableCardGrid } from '@alexandermcqueengit/amq-ui-package';
import Divider from '@mui/material/Divider';
import { BestSellerObj, TopFiveStoresTableObj } from '../../helper';
import { useAppSelector } from '../../store/hooks';
import { InitialState } from '../../store/reducer';
import './BestSellerGrid.scss';
import ProductImage from '../ProductImage/ProductImage';

interface CardContentProps {
  SFC: string;
  imgClass: string;
  ImageURL: string;
  StyleDesc: string;
  ColorDesc: string;
  Week: string;
  SalesQty: string | null;
  SalesEuro: string;
  STRec: string | null;
  STTotal: string | null;
  Icon: string;
  MCO: string;
  RetailPrice: string | null;
  OnHand: string | null;
  InTransit: string | null;
  PendingFromProductionQty: string | null;
  InWarehouseQty: string | null;
  topFiveStores: TopFiveStoresTableObj[];
}

const LargeCardContent = ({ item }: { item: CardContentProps }) => {
  const {
    SFC,
    imgClass,
    ImageURL,
    StyleDesc,
    ColorDesc,
    Week,
    SalesQty,
    SalesEuro,
    STRec,
    STTotal,
    Icon,
    MCO,
    RetailPrice,
    OnHand,
    InTransit,
    PendingFromProductionQty,
    InWarehouseQty,
    topFiveStores
  } = item;
  const selectedFilters = useAppSelector((state: InitialState) => state.selectedFilters);
  return (
    <div className="card">
      <div className="display_justifyAlign card__img-holder card__img-holder--large-card">
        <ProductImage SFC={SFC} styleDesc={StyleDesc} />
      </div>
      <div className="card__desc-box--large-card">
        {(Icon !== 'NOT DEFINED' || MCO !== 'NOT DEFINED') && (
          <div className="card__ICONMCO-box">
            <div className="card__ICONMCO-productTag">{Icon === 'NOT DEFINED' ? MCO : Icon}</div>
          </div>
        )}
        <div className="card__description card__description--large-card">
          <span>{SFC}</span>
          <span>{StyleDesc}</span>
          <span>{ColorDesc}</span>
          <span>EUR {RetailPrice}</span>
          <span className="card__OH-desc">First Week OH</span>
          <span>{Week}</span>
        </div>
        <Divider />
        <div className="card__sales-description card__sales-description--largeCard">
          <table className="card__sales-table card__sales-table--large-card">
            <tbody>
              <tr>
                <td className="card__table-header" align="left">
                  Sales
                </td>
              </tr>
              <tr>
                <td align="left">Sales Qty</td>
                <td align="right">{SalesQty !== null && parseFloat(SalesQty)}</td>
              </tr>
              <tr>
                <td align="left">Sales Value</td>
                <td align="right">{parseFloat(SalesEuro).toFixed(1)}K</td>
              </tr>
              {selectedFilters.iconCO.length === 0 ? (
                <>
                  <tr>
                    <td align="left">Rec. Sell Thru</td>
                    <td align="right">{STRec !== null && parseFloat(STRec)}%</td>
                  </tr>
                  <tr>
                    <td align="left">Total Sell Thru</td>
                    <td align="right">{STTotal !== null && parseFloat(STTotal)}%</td>
                  </tr>{' '}
                </>
              ) : (
                ''
              )}
            </tbody>
          </table>
          {!!topFiveStores.length && (
            <table className="card__sales-table card__sales-table--large-card">
              <tbody>
                <tr>
                  <td className="card__table-header" align="left">
                    Top 5 Stores
                  </td>
                  <td className="card__table-header" align="right">
                    Qty Sold
                  </td>
                </tr>
                {topFiveStores.map((store: TopFiveStoresTableObj) => (
                  <tr key={store.LocationCode}>
                    <td align="left">
                      {store.ConsolidatedLocationDesc != null ? store.ConsolidatedLocationDesc : ''}
                    </td>
                    <td align="right">{store.TotalSalesQty}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!topFiveStores.length && <p>No Store Data Available</p>}
          <table className="card__sales-table card__sales-table--large-card">
            <tbody>
              <tr>
                <td className="card__table-header" align="left">
                  Available Stock
                </td>
                <td className="card__table-header" align="right">
                  Qty
                </td>
              </tr>
              <tr>
                <td align="left">OH/ In Transit</td>
                <td align="right">{OnHand && InTransit ? +OnHand + +InTransit : 'N/A'}</td>
              </tr>
              <tr>
                <td align="left">In Warehouse</td>
                <td align="right">{InWarehouseQty}</td>
              </tr>
              <tr>
                <td align="left">Pend From Prod</td>
                <td align="right">{PendingFromProductionQty}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const SmallCardContent = ({ item }: { item: CardContentProps }) => {
  const {
    SFC,
    StyleDesc,
    ColorDesc,
    Week,
    SalesQty,
    SalesEuro,
    STRec,
    STTotal,
    Icon,
    MCO,
    RetailPrice
  } = item;
  const selectedFilters = useAppSelector((state: InitialState) => state.selectedFilters);
  return (
    <div className="card">
      <div className="display_justifyAlign card__img-holder">
        <ProductImage SFC={SFC} styleDesc={StyleDesc} />
      </div>
      <div className="card__desc-box">
        {(Icon !== 'NOT DEFINED' || MCO !== 'NOT DEFINED') && (
          <div className="card__ICONMCO-box">
            <div className="card__ICONMCO-productTag">{Icon === 'NOT DEFINED' ? MCO : Icon}</div>
          </div>
        )}
        <div className="card__description">
          <span>{SFC}</span>
          <span>{StyleDesc}</span>
          <span>{ColorDesc}</span>
          <span>EUR {RetailPrice}</span>
          <span className="card__OH-desc">First Week OH</span>
          <span>{Week}</span>
        </div>
        <Divider />
        <div className="card__sales-description">
          <table>
            <tbody className="card__sales-table">
              <tr>
                <td>Sales Qty</td>
                <td>{SalesQty !== null && parseFloat(SalesQty)}</td>
              </tr>
              <tr>
                <td>Sales Value</td>
                <td>{parseFloat(SalesEuro).toFixed(1)}K</td>
              </tr>
              {selectedFilters.iconCO.length === 0 ? (
                <>
                  <tr>
                    <td align="left">Rec. Sell Thru</td>
                    <td align="right">{STRec !== null && parseFloat(STRec)}%</td>
                  </tr>
                  <tr>
                    <td align="left">Total Sell Thru</td>
                    <td align="right">{STTotal !== null && parseFloat(STTotal)}%</td>
                  </tr>{' '}
                </>
              ) : (
                ''
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const BestSellerGrid = ({ value }: { value: BestSellerObj[] }) => (
  <ExpandableCardGrid
    smallCardContent={SmallCardContent}
    dataArray={value}
    largeCardContent={LargeCardContent}
  />
);

export default BestSellerGrid;
