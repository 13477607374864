import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { handleBestSellerToggle, toggleDrawerOpen } from '../store/actions';
import { connect } from 'react-redux';
import { InitialState } from '../store/reducer';
import BestSellers from './bestSellers';
import FilterAccordians from '../components/filters/filterAccordians';
import './drawerPageContainer.scss';
import BasicModal from '../components/Modal/BasicModal';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { SelectedFilters } from '.././store/actions';
import { SquareToggle } from '@alexandermcqueengit/amq-ui-package';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

declare module '@mui/material/Modal' {
  interface ModalUnstyledComponentsPropsOverrides {
    invisible?: boolean;
  }
}

type DrawPageContainerProps = {
  handleBestSellerToggle: (bestSellerType: string) => { type: string; payload: string };
  toggleDrawerOpen: any;
  reportWeek: string;
  bestSellerType: string;
  selectedFilters: SelectedFilters;
};
const DrawPageContainer = ({
  handleBestSellerToggle,
  toggleDrawerOpen,
  bestSellerType,
  selectedFilters
}: DrawPageContainerProps) => {
  const [openDraw, setOpenDraw] = useState(true);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    event.target.checked === true
      ? handleBestSellerToggle('Top 20')
      : handleBestSellerToggle('Generic');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        className="page__drawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={openDraw}>
        <DrawerHeader className="test">
          <IconButton onClick={() => toggleDrawerOpen(setOpenDraw(!openDraw))}>
            {openDraw && <CloseIcon className="drawer-icon" />}
          </IconButton>
        </DrawerHeader>
        <div className="display_justifyAlign flex-Col">
          {selectedFilters.season.length ? (
            <SquareToggle checked={checked} handleChange={handleChange} label="seasonal top 20" />
          ) : (
            ''
          )}
          <FilterAccordians />
        </div>
      </Drawer>
      <Main open={openDraw}>
        <DrawerHeader className="page__container">
          <Button
            disableRipple={true}
            className="page__drawer-toggle"
            onClick={() => {
              setOpenDraw(!openDraw);
            }}>
            {!openDraw && <MenuIcon className="page__drawer-toggle-icon" />}
          </Button>
          <div className="page__header">
            <div></div>
            <span className="page__title">
              {bestSellerType === 'Generic' ? 'Best Sellers' : 'Top 20 Best Sellers'}
              <span className="page__season-header">
                {selectedFilters.season[0] !== undefined && `${selectedFilters.season[0]}`}
              </span>
            </span>

            <BasicModal />
          </div>
          <BestSellers />
        </DrawerHeader>
      </Main>
    </Box>
  );
};

const mapStateToProps = (state: InitialState) => ({
  bestSellerType: state.bestSellerType,
  reportWeek: state.reportWeek,
  getBestSellerProducts: state.bestSellerProducts,
  selectedFilters: state.selectedFilters
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  toggleDrawerOpen: (drawerState: boolean) => dispatch(toggleDrawerOpen(drawerState)),
  handleBestSellerToggle: (bestSellerType: string) =>
    dispatch(handleBestSellerToggle(bestSellerType))
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawPageContainer);
