import API from '../API';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import {
  groupAndSortAll,
  filterData,
  BestSellerObj,
  kpiObject,
  createRequestBody,
  responseDataAdapter,
  groupSFCsByRank,
  TopFiveStoresData,
  mergeBestsellerData,
  sortBestSellerObjects
} from '../helper';

// Action Type Types
export type SelectedFilters = {
  season: string[];
  event: string[];
  functionCode: string[];
  iconCO: string[];
};

export interface UpdateFiltersAction extends Action {
  type: typeof UPDATE_FILTERS;
  payload: { name: keyof SelectedFilters; value: string };
}

// Action Type
export const GET_USERNAME = 'getUsername';
export const SET_BESTSELLERS = 'getBestSellers';
export const TOGGLE_BESTSELLER_TYPE = 'toggleBestSellerType';
export const TOGGLE_DRAWER_OPEN = 'toggleDrawerOpen';
export const SET_REPORT_WEEK = 'setReportWeek';
export const UPDATE_FILTERS = 'updateFilters';
export const START_LOADING = 'startLoading';
export const STOP_LOADING = 'stopLoading';
export const DOWNLOAD_READY = `downloadReady`;
export const CLEAR_DOWNLOAD = `clearDownload`;
export const UPDATE_KPIS = 'updateKpis';
export const UPDATE_PDFLINKS = 'updatePdfLinks';
export const DOWNLOAD_FAILED = 'downloadFailed';
export const UPDATE_AVAILABLE_EVENT_PDFS = 'updateEventPDFs';
export const UPDATE_USER = 'updateUser';
export const SET_TOKEN = 'setToken';
export const SET_ROLE = 'setRole';
export const SET_TOP_FIVE_STORES = 'setTopFiveStores';

// Action Creators

export const setRole = (role: string) => ({
  type: SET_ROLE,
  payload: role
});

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  payload: token
});

export const updateUser = (name: string) => ({
  type: UPDATE_USER,
  payload: name
});

export const getUsername = (name: string) => ({
  type: GET_USERNAME,
  payload: name
});

export const getBestSellerProducts = (token: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    let responseData: BestSellerObj[] = [];
    let topFiveStoresDataObj = {};
    try {
      const { data } = await API.getSeasons(token);
      const seasonsArray: string[] = data.map(
        (obj: Record<string, string>) => Object.values(obj)[0]
      );
      const { data: topFiveStoresData } = await API.getTopFiveStores(token);
      const topFiveStoresGrouped = groupSFCsByRank(topFiveStoresData);
      topFiveStoresDataObj = topFiveStoresGrouped;

      dispatch({ type: SET_TOP_FIVE_STORES, payload: topFiveStoresDataObj });

      for (const originalBornSeason of seasonsArray) {
        const { data } = await API.getBestSellers(token, originalBornSeason);
        const bestSellerProductsGrouped = mergeBestsellerData(topFiveStoresGrouped, data);
        responseData = responseDataAdapter(bestSellerProductsGrouped);
        const latestSeason = seasonsArray[0];

        dispatch({ type: SET_BESTSELLERS, payload: responseData });
        dispatch({ type: UPDATE_FILTERS, payload: { name: 'season', value: latestSeason } });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchBestSellerPdf = (
  seasonCode: string,
  eventCode: string,
  eventName: string,
  OriginalBornSeason: string,
  OriginalEvent: string,
  bestSellerProducts: BestSellerObj[],
  kpis: kpiObject
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(generatingPDF(eventName));
    try {
      const filters = {
        season: [OriginalBornSeason],
        event: [OriginalEvent],
        functionCode: [],
        iconCO: []
      };

      const filteredPdfProducts = filterData(bestSellerProducts, filters);
      const groupedProducts = sortBestSellerObjects(filteredPdfProducts, 'top20');
      const groupedAndSortedPdfProductsTop20 = groupAndSortAll(
        groupedProducts,
        'Function',
        'SalesEuro',
        true,
        'OriginalEvent'
      );

      const requestBody = createRequestBody(groupedAndSortedPdfProductsTop20, kpis);

      const { data } = await API.getBestSellerPdf(seasonCode, eventCode, requestBody);

      dispatch(updatedPdfLinks(eventName, data.pdf));
      if (data && data.pdf !== '') {
        dispatch(loadingComplete(eventName));
        const link = document.createElement('a');
        link.href = data.pdf;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        dispatch(downloadFailed(eventName));
      }
    } catch (e) {
      dispatch(downloadFailed(eventName));
    }
  };
};

export const handleBestSellerToggle = (bestSellerType: string) => ({
  type: TOGGLE_BESTSELLER_TYPE,
  payload: bestSellerType
});

export const toggleDrawerOpen = (drawerState: boolean) => ({
  type: TOGGLE_DRAWER_OPEN,
  payload: !drawerState
});

export const updateFilters = (name: keyof SelectedFilters, value: string) => {
  return {
    type: UPDATE_FILTERS,
    payload: {
      name,
      value
    }
  };
};

export const generatingPDF = (id: string) => {
  return {
    type: START_LOADING,
    payload: id
  };
};

export const loadingComplete = (id: string) => {
  return {
    type: STOP_LOADING,
    payload: id
  };
};

export const readyToDownload = (id: string) => {
  return {
    type: DOWNLOAD_READY,
    payload: id
  };
};

export const clearDownload = (id: string) => {
  return {
    type: CLEAR_DOWNLOAD,
    payload: id
  };
};

export const generatedKpis = (kpis: kpiObject) => {
  return {
    type: UPDATE_KPIS,
    payload: kpis
  };
};

export const updatedPdfLinks = (id: string, link: string) => {
  return {
    type: UPDATE_PDFLINKS,
    payload: {
      id,
      link
    }
  };
};

export const updateAvailableEventPDFs = (availableEvents: string[]) => {
  return {
    type: UPDATE_AVAILABLE_EVENT_PDFS,
    payload: availableEvents
  };
};

export const downloadFailed = (id: string) => {
  return {
    type: DOWNLOAD_FAILED,
    payload: id
  };
};

export const setTopFiveStores = (topFiveStores: TopFiveStoresData) => ({
  type: SET_TOP_FIVE_STORES,
  payload: topFiveStores
});
