import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import React from 'react';
import './BasicModal.scss';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { clearDownload, fetchBestSellerPdf, SelectedFilters } from '../../store/actions';
import { connect } from 'react-redux';
import { InitialState, PdfState, BestSellerPdfLinks } from '../../store/reducer';
import { BestSellerObj, kpiObject, getBestSellerPDFs } from '../../helper';
import { bestSellerPdfDict } from './bestSellerPDFData';

type IPdfLink = {
  pdf: {
    link: string | null;
    name: string;
    season: string;
    seasonCode: string;
    eventCode: string;
    OriginalBornSeason: string;
    OriginalEvent: string;
  };

  pdfState: PdfState;

  handlePDFClick: (
    eventName: string,
    seasonCode: string,
    eventCode: string,
    OriginalBornSeason: string,
    OriginalEvent: string,
    bestSellerProducts: BestSellerObj[],
    kpiKeys: kpiObject
  ) => void;
  bestSellerProducts: BestSellerObj[];
  kpis: kpiObject;
};

const PdfLink = ({ pdfState, pdf, handlePDFClick, bestSellerProducts, kpis }: IPdfLink) => {
  const { season, seasonCode, eventCode, OriginalBornSeason, OriginalEvent, name } = pdf;

  const CircularColor = () => {
    return (
      <Stack className="modal__spinner-component" spacing={2} direction="row">
        <CircularProgress classes={{ root: 'modal__spinner-container' }} />
      </Stack>
    );
  };

  return (
    <li className="modal__list-item">
      <button
        className="modal__list-text"
        onClick={(event) => {
          event.preventDefault();
          if (pdfState[season] === 'downloadReady') return;
          handlePDFClick(
            season,
            seasonCode,
            eventCode,
            OriginalBornSeason,
            OriginalEvent,
            bestSellerProducts,
            kpis
          );
        }}>
        {name}
      </button>
      <div className="modal__icons">
        <div
          className={`modal__spinner ${
            pdfState[season] === 'generating' ? 'modal__spinner--active' : ''
          }`}>
          <CircularColor />
        </div>
        <div
          className={`modal__failed ${
            pdfState[season] === 'downloadFailed' ? 'modal__failed--active' : ''
          }`}>
          <p>Download Failed</p>
        </div>
        <svg
          className={`modal__checkmark ${
            pdfState[season] === 'downloadComplete' ? 'modal__checkmark--active' : ''
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
    </li>
  );
};

type BasicModalProps = {
  fetchBestSellerPdf: (
    eventName: string,
    seasonCode: string,
    eventCode: string,
    OriginalBornSeason: string,
    OriginalEvent: string,
    bestSellerProducts: BestSellerObj[],
    kpis: kpiObject
  ) => void;
  bestSellerProducts: BestSellerObj[];
  bestSellerType: string;
  kpis: kpiObject;
  clearDownload: (eventName: string) => void;
  bestSellerPdfLinks: BestSellerPdfLinks;
  pdfState: PdfState;
  availableEventPDFs: string[];
  selectedFilters: SelectedFilters;
  role: string;
};

const BasicModal = ({
  fetchBestSellerPdf,
  bestSellerProducts,
  kpis,
  clearDownload,
  bestSellerPdfLinks,
  pdfState,
  bestSellerType,
  availableEventPDFs,
  selectedFilters
}: BasicModalProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = (bestSellerPdfs: { [key: string]: null | string }[]) => {
    setOpen(false);
    bestSellerPdfs.map((pdf: { [key: string]: null | string }) => clearDownload(pdf.season || ''));
  };

  type downloadItem = {
    eventName: string;
    seasonCode: string;
    eventCode: string;
    OriginalBornSeason: string;
    OriginalEvent: string;
    bestSellerProducts: BestSellerObj[];
    kpis: kpiObject;
  };

  const downloadQueue: downloadItem[] = [];
  let isDownloading = false;

  const handlePDFClick = async (
    eventName: string,
    seasonCode: string,
    eventCode: string,
    OriginalBornSeason: string,
    OriginalEvent: string,
    bestSellerProducts: BestSellerObj[],
    kpis: kpiObject
  ) => {
    if (downloadQueue) {
      const downloadItem = {
        eventName,
        seasonCode,
        eventCode,
        OriginalBornSeason,
        OriginalEvent,
        bestSellerProducts,
        kpis
      };

      downloadQueue.push(downloadItem);

      if (isDownloading) {
        return;
      }

      await processNextDownload();
    }
  };

  const processNextDownload = async () => {
    if (downloadQueue) {
      if (downloadQueue.length === 0) {
        isDownloading = false;
        return;
      }

      isDownloading = true;

      const downloadItem = downloadQueue.shift();

      try {
        if (downloadItem) {
          const {
            seasonCode,
            eventCode,
            eventName,
            OriginalBornSeason,
            OriginalEvent,
            bestSellerProducts,
            kpis
          } = downloadItem;

          await fetchBestSellerPdf(
            seasonCode,
            eventCode,
            eventName,
            OriginalBornSeason,
            OriginalEvent,
            bestSellerProducts,
            kpis
          );

          await processNextDownload();
        }
      } catch (error) {
        console.error('Download error:', error);
        await processNextDownload();
      }
    }
  };

  const bestSellerPDFs = getBestSellerPDFs(
    bestSellerProducts,
    bestSellerPdfLinks,
    bestSellerPdfDict,
    availableEventPDFs,
    selectedFilters
  );

  return (
    <div>
      {bestSellerType !== 'Generic' && (
        <Button onClick={handleOpen} className="modal__pdfbutton">
          print pdf
        </Button>
      )}
      <Modal
        className="modal__conatainer"
        open={open}
        onClose={() => handleClose(bestSellerPDFs)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal__box">
          <CloseIcon onClick={() => handleClose(bestSellerPDFs)} className="modal__close-icon" />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <p className="modal__title">Download PDF</p>
          </Typography>
          <div className="modal__description" id="modal-modal-description">
            <ul>
              {bestSellerPDFs.map((pdf: IPdfLink['pdf']) => {
                const kpiKeys = Object.keys(kpis).filter((key) =>
                  key.split(' - ')[0].includes(pdf.OriginalEvent)
                );
                const kpisObj = kpiKeys.reduce(
                  (prev, kpiKey) => ({
                    ...prev,
                    [kpiKey]: kpis[kpiKey]
                  }),
                  {}
                );

                return (
                  <PdfLink
                    bestSellerProducts={bestSellerProducts}
                    pdf={pdf}
                    pdfState={pdfState}
                    handlePDFClick={handlePDFClick}
                    kpis={kpisObj}
                    key={pdf.name}
                  />
                );
              })}
            </ul>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: InitialState) => ({
  pdfState: state.bestSellerPdfs,
  bestSellerProducts: state.bestSellerProducts,
  bestSellerType: state.bestSellerType,
  kpis: state.kpis,
  bestSellerPdfLinks: state.bestSellerPdfLinks,
  availableEventPDFs: state.availableEventPDFs,
  selectedFilters: state.selectedFilters,
  role: state.role
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchBestSellerPdf: (
    eventName: string,
    seasonCode: string,
    eventCode: string,
    OriginalBornSeason: string,
    OriginalEvent: string,
    bestSellerProducts: BestSellerObj[],
    kpis: kpiObject
  ) =>
    dispatch(
      fetchBestSellerPdf(
        eventName,
        seasonCode,
        eventCode,
        OriginalBornSeason,
        OriginalEvent,
        bestSellerProducts,
        kpis
      )
    ),
  clearDownload: (eventName: string) => dispatch(clearDownload(eventName))
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicModal);
