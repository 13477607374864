import imageSrc from '../../assets/noImage.png';

type productImage = {
  SFC: string;
  styleDesc: string;
};

const ProductImage = ({ SFC, styleDesc }: productImage) => {
  return (
    <img
      className="card__img"
      src={`https://static-amq-images.icarrteamdomain.co.uk/AMQ/photos_tiny/${SFC}_A.jpg`}
      alt={styleDesc}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        const fallbackSrc = `https://static-amq-images.icarrteamdomain.co.uk/AMQ/sketches/${SFC.substring(
          0,
          6
        )}.jpg`;

        const fallbackImage = new Image();
        fallbackImage.onerror = () => {
          target.src = imageSrc;
        };
        fallbackImage.src = fallbackSrc;

        target.src = fallbackSrc;
      }}
    />
  );
};

export default ProductImage;
