import {
  GET_USERNAME,
  SET_BESTSELLERS,
  TOGGLE_BESTSELLER_TYPE,
  TOGGLE_DRAWER_OPEN,
  SET_REPORT_WEEK,
  UPDATE_FILTERS,
  START_LOADING,
  STOP_LOADING,
  DOWNLOAD_READY,
  UpdateFiltersAction,
  CLEAR_DOWNLOAD,
  UPDATE_KPIS,
  UPDATE_PDFLINKS,
  DOWNLOAD_FAILED,
  UPDATE_AVAILABLE_EVENT_PDFS,
  UPDATE_USER,
  SET_TOKEN,
  SET_ROLE,
  SET_TOP_FIVE_STORES
} from './actions';
import {
  updateSelectedFilters,
  filterData,
  kpiObject,
  TopFiveStoresData,
  BestSellerObj
} from '../helper';

export type PdfState = {
  [key: string]: string | null;
};

export type BestSellerPdfLinks = {
  [key: string]: string | null;
};

export type InitialState = {
  name: string;
  bestSellerProducts: BestSellerObj[];
  filteredBestSellerProducts: BestSellerObj[];
  bestSellerType: string;
  openDraw: boolean | undefined;
  reportWeek: string;
  selectedFilters: {
    season: string[];
    event: string[];
    functionCode: string[];
    iconCO: string[];
  };
  bestSellerPdfs: PdfState;
  kpis: kpiObject;
  bestSellerPdfLinks: BestSellerPdfLinks;
  availableEventPDFs: string[];
  nameUpdate: string;
  token: string;
  role: string;
  topFiveStores: TopFiveStoresData;
};

const initialState: InitialState = {
  name: '',
  bestSellerProducts: [],
  filteredBestSellerProducts: [],
  bestSellerType: 'Generic',
  openDraw: true,
  reportWeek: '',
  selectedFilters: {
    season: [],
    event: [],
    functionCode: [],
    iconCO: []
  },
  bestSellerPdfs: {
    'Men Early': null,
    'Women Early': null,
    'Women Main': null,
    'Men Main': null,
    'Women Pre': null
  },
  kpis: {},
  bestSellerPdfLinks: {
    'Men Early': null,
    'Women Early': null,
    'Women Main': null,
    'Men Main': null,
    'Women Pre': null
  },
  availableEventPDFs: [],
  nameUpdate: '',
  token: '',
  role: '',
  topFiveStores: {}
};

type State = typeof initialState;

// Reducer
export const reducer = (state = initialState, action: any): State => {
  switch (action.type) {
    case SET_ROLE:
      return {
        ...state,
        role: action.payload
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case UPDATE_USER:
      return {
        ...state,
        nameUpdate: action.payload
      };
    case GET_USERNAME:
      return {
        ...state,
        name: action.payload
      };
    case SET_BESTSELLERS:
      return {
        ...state,
        bestSellerProducts: [...state.bestSellerProducts, ...action.payload],
        filteredBestSellerProducts: action.payload
      };

    case SET_TOP_FIVE_STORES:
      return {
        ...state,
        topFiveStores: action.payload
      };
    case SET_REPORT_WEEK:
      return {
        ...state,
        reportWeek: action.payload
      };
    case TOGGLE_BESTSELLER_TYPE:
      return {
        ...state,
        bestSellerType: action.payload
      };
    case TOGGLE_DRAWER_OPEN:
      return {
        ...state,
        openDraw: action.payload
      };
    case UPDATE_FILTERS: {
      const { name, value } = (action as UpdateFiltersAction).payload;
      const selectedFilters = updateSelectedFilters(state.selectedFilters, name, value);
      const filteredBestSellerProducts = filterData(state.bestSellerProducts, selectedFilters);

      return {
        ...state,
        filteredBestSellerProducts,
        selectedFilters
      };
    }
    case START_LOADING:
      return {
        ...state,
        bestSellerPdfs: {
          ...state.bestSellerPdfs,
          [action.payload]: 'generating'
        }
      };
    case STOP_LOADING:
      return {
        ...state,
        bestSellerPdfs: {
          ...state.bestSellerPdfs,
          [action.payload]: 'downloadComplete'
        }
      };
    case DOWNLOAD_READY:
      return {
        ...state,
        bestSellerPdfs: {
          ...state.bestSellerPdfs,
          [action.payload]: 'downloadReady'
        }
      };
    case CLEAR_DOWNLOAD:
      return {
        ...state,
        bestSellerPdfs: {
          ...state.bestSellerPdfs,
          [action.payload]: null
        }
      };
    case UPDATE_KPIS:
      return {
        ...state,
        kpis: action.payload
      };
    case UPDATE_PDFLINKS:
      return {
        ...state,
        bestSellerPdfLinks: {
          ...state.bestSellerPdfLinks,
          [action.payload.id]: action.payload.link
        }
      };
    case DOWNLOAD_FAILED:
      return {
        ...state,
        bestSellerPdfs: {
          ...state.bestSellerPdfs,
          [action.payload]: 'downloadFailed'
        }
      };
    case UPDATE_AVAILABLE_EVENT_PDFS:
      return {
        ...state,
        availableEventPDFs: action.payload
      };
    default:
      return {
        ...state
      };
  }
};
