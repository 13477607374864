import './navbar.scss';
import { InitialState } from '../../store/reducer';
import { connect } from 'react-redux';

type NavBarProps = {
  name: string;
};

const NavBarLogo = ({ name }: NavBarProps) => {
  const redirectAMQ = () => {
    window.localStorage.setItem('model_part', 'ModelA');
    if (window.top) window.top.location = 'https://www.alexandermcqueen.io';
  };

  return (
    <nav className="display_flex navbarmaster">
      <div className="display_flex">
        <a
          href="https://www.alexandermcqueen.io"
          onClick={redirectAMQ}
          className="display_flex cursor_pointer"
        >
          <img
            src="https://static-cdn-assets.icarrteamdomain.co.uk/images/input-tables/AMQ-LOGO-2.png"
            className="amqlogo amq-logo-invert"
            alt="alexander mcqueen logo"
          />
        </a>
      </div>
      <div className="username">{name && <span>Hi {name.split('.')[0]}</span>}</div>
    </nav>
  );
};

const mapStateToProps = (state: InitialState) => ({
  name: state.name || ''
});

export default connect(mapStateToProps)(NavBarLogo);
