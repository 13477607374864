import './Loading.scss';
import { LinearProgress } from '@mui/material';

const Loading = () => {
  return (
    <div className="loading">
      <h2 className="loading__text">Loading products</h2>
      <LinearProgress color="inherit" />
    </div>
  );
};

export default Loading;
