import { SelectedFilters } from './store/actions';
import { BestSellerPdfLinks } from './store/reducer';

export type BestSellerObj = {
  SeasonCode: string;
  OriginalBornSeason: string;
  EventCode: string;
  GlobalEventSort: string;
  SFC: string;
  Function: string;
  FunctionOrder: string;
  Line: string;
  LineOrder: string | null;
  StyleDesc: string;
  ColorDesc: string;
  SalesQty: string | null;
  SalesEuro: string;
  STTotal: string | null;
  Week: string;
  OriginalEvent: string;
  SFCRank: string;
  ImageURL: string;
  ReportWeek: string;
  STRec: string | null;
  ImageLink?: string;
  Icon: string;
  MCO: string;
  OHStockQty: string | null;
  PendingFromProdQty: string | null;
  InWarehouseQty: string | null;
  topFiveStores: TopFiveStoresObj[];
};

export type kpiObject = {
  [key: string]: {
    totalVolume: number;
    totalSales: number;
    totalSalesPercent: number;
    totalVolumePercent: number;
  };
};

export type KPITotals = {
  totalVolume: number;
  totalSales: number;
  totalVolumePercent: number;
  totalSalesPercent: number;
};

export type TopFiveStoresObj = {
  SFC: string;
  Rank: string;
  TotalSalesQty: string;
  LocationCode: string;
  ConsolidatedLocationDesc: string | null;
};

export type TopFiveStoresTableObj = {
  LocationCode: string;
  TotalSalesQty: string;
  ConsolidatedLocationDesc: string | null;
};

export type TopFiveStoresData = {
  [key: string]: TopFiveStoresObj;
};

export const capitaliseEveryWord = (string: string) => {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const calculateTotals = (items: BestSellerObj[]): KPITotals => {
  const { totalVolume, totalSales } = items.reduce<{ totalVolume: number; totalSales: number }>(
    (acc, { SalesEuro, SalesQty }) => {
      acc.totalSales += +SalesEuro;
      acc.totalVolume += +(SalesQty || 0);
      return acc;
    },
    { totalSales: 0, totalVolume: 0 }
  );

  const KPIObject: KPITotals = {
    totalVolume,
    totalSales: +totalSales.toFixed(1),
    totalVolumePercent: 0,
    totalSalesPercent: 0
  };

  return KPIObject;
};

export const calculateKpisTop20 = (allItems: BestSellerObj[], top20Items: BestSellerObj[]) => {
  const { totalVolume: totalQtyOfTop20, totalSales: totalValueOfTop20 } =
    calculateTotals(top20Items);
  const { totalVolume: totalGroupedQty, totalSales: totalGroupedValue } = calculateTotals(allItems);

  return {
    totalVolume: totalQtyOfTop20.toString(),
    totalVolumePercent: ((totalQtyOfTop20 / totalGroupedQty) * 100).toFixed(1),
    totalSales: totalValueOfTop20.toString(),
    totalSalesPercent: ((totalValueOfTop20 / totalGroupedValue) * 100).toFixed(1)
  };
};

export const sortBestSellerObjects = (bestSellerProducts: any, bestSellerType: string) => {
  return [...bestSellerProducts].sort((a: any, b: any) => {
    if (+a.GlobalEventSort === +b.GlobalEventSort) {
      if (bestSellerType === 'Generic') {
        if (+a.FunctionOrder === +b.FunctionOrder) {
          return a.Line.localeCompare(b.Line);
        }
      }
      return +a.FunctionOrder - +b.FunctionOrder;
    }
    return (+a.GlobalEventSort || 0) - (+b.GlobalEventSort || 0);
  });
};

export const groupAndSortAll = (
  arr: BestSellerObj[],
  Function: keyof Pick<BestSellerObj, 'Function'>,
  SalesEuro: keyof Pick<BestSellerObj, 'SalesEuro'>,
  top20 = false,
  OriginalEvent?: keyof Pick<BestSellerObj, 'OriginalEvent'>,
  Line?: keyof Pick<BestSellerObj, 'Line'>
): { [key: string]: BestSellerObj[] } => {
  const grouped: { [key: string]: BestSellerObj[] } = {};
  arr.forEach((item: BestSellerObj) => {
    const lineCapitalised =
      Line && item[Line] && isString(item[Line])
        ? item[Line]?.toLowerCase()
            .split(' ')
            .map((str) => capitaliseEveryWord(str))
            .join(' ')
        : '';
    const groupKey =
      (OriginalEvent ? item[OriginalEvent] + ' - ' : '') +
      item[Function] +
      (lineCapitalised ? ' - ' + lineCapitalised : '');
    if (!grouped[groupKey]) {
      grouped[groupKey] = [];
    }
    grouped[groupKey].push(item);
  });
  for (const key in grouped) {
    grouped[key].sort((a: BestSellerObj, b: BestSellerObj) => {
      if (a[SalesEuro] === null) return -1;
      if (b[SalesEuro] === null) return 1;

      return (
        parseFloat(b[SalesEuro]?.replace(/\./g, '') || '0') -
        parseFloat(a[SalesEuro]?.replace(/\./g, '') || '0')
      );
    });
    if (top20) grouped[key] = grouped[key].slice(0, 20);
  }
  return grouped;
};

export const mergeObjects = (top20Products: any, allProducts: any) => {
  const mergedProducts: any = {};
  Object.keys(top20Products).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (allProducts.hasOwnProperty(key)) {
      mergedProducts[key] = {
        top20: top20Products[key],
        allProducts: allProducts[key]
      };
    }
  });
  return mergedProducts;
};

const isString = (value: any): value is string => {
  return typeof value === 'string';
};

export const filterData = (data: BestSellerObj[], selectedFilters: SelectedFilters) => {
  const seasonFilterActive = !(selectedFilters.season.length > 0);
  const eventFilterActive = !(selectedFilters.event.length > 0);
  const functionFilterActive = !(selectedFilters.functionCode.length > 0);
  const iconCOFilterActive = selectedFilters.iconCO.length > 0;

  let filteredData = data;

  if (iconCOFilterActive) {
    filteredData = filteredData.filter(
      (obj: BestSellerObj) =>
        selectedFilters.iconCO.includes(obj.Icon) || selectedFilters.iconCO.includes(obj.MCO)
    );
    selectedFilters.season = [];
    selectedFilters.event = [];

    if (!functionFilterActive) {
      filteredData = filteredData.filter((obj: BestSellerObj) =>
        selectedFilters.functionCode.includes(obj.Function)
      );
      selectedFilters.season = [];
      selectedFilters.event = [];
      selectedFilters.functionCode = [];
    }
  } else {
    filteredData = filteredData.filter(
      (obj: BestSellerObj) =>
        (seasonFilterActive ||
          selectedFilters.season.includes(obj.OriginalBornSeason) ||
          selectedFilters.season.includes('All Seasons')) &&
        (eventFilterActive || selectedFilters.event.includes(obj.OriginalEvent)) &&
        (functionFilterActive || selectedFilters.functionCode.includes(obj.Function))
    );
    selectedFilters.iconCO = [];
  }
  return filteredData;
};

export const updateSelectedFilters = (
  bestSellerFilter: SelectedFilters,
  key: keyof SelectedFilters,
  value: string
) => {
  const bestSellerFilterCategory = Array.isArray(bestSellerFilter[key])
    ? [...bestSellerFilter[key]]
    : [];
  const indexOfItem = bestSellerFilterCategory.indexOf(value);

  if (key === 'season') {
    return {
      ...bestSellerFilter,
      ['iconCO']: [],
      [key]: [value]
    };
  } else {
    if (indexOfItem + 1) {
      bestSellerFilterCategory.splice(indexOfItem, 1);
    } else {
      bestSellerFilterCategory.push(value);
    }

    return {
      ...bestSellerFilter,
      [key]: bestSellerFilterCategory
    };
  }
};

export const createRequestBody = (top20: { [key: string]: BestSellerObj[] }, kpis: kpiObject) => {
  return Object.entries(top20).reduce((prev, [key, value]) => {
    const kpiData: any = kpis[key];

    return {
      ...prev,
      [key]: {
        volumeTotal: kpiData.totalVolume || 0,
        volumePercent: kpiData.totalVolumePercent || 0,
        valueTotal: kpiData.totalSales || 0,
        valuePercent: kpiData.totalSalesPercent || 0,
        items: [...value]
      }
    };
  }, {});
};

export const getBestSellerPDFs = (
  bestSellerProducts: BestSellerObj[],
  bestSellerPdfLinks: BestSellerPdfLinks,
  bestSellerPdfDict: any,
  availableEventPDFs: any,
  selectedFilters: SelectedFilters
) => {
  let mappedPDFDict = bestSellerPdfDict(bestSellerPdfLinks).filter((obj: any) =>
    availableEventPDFs.includes(obj.OriginalEvent)
  );
  const seasonCode = selectedFilters.season[0];
  mappedPDFDict = mappedPDFDict.map((item: any) => {
    return {
      ...item,
      name: `${item.OriginalEvent} - ${selectedFilters.season[0]} - Seasonal Top 20`,
      seasonCode,
      OriginalBornSeason: selectedFilters.season[0]
    };
  });
  return mappedPDFDict;
};

// ------------ Filter ------------- //
export const getLatestSeason = (
  arr: BestSellerObj[],
  key: keyof BestSellerObj | string
): string => {
  const uniqueFilterOptions = [
    ...new Set(
      arr.map((item: any) => {
        return item[key];
      })
    )
  ];
  const sortedSeasons = uniqueFilterOptions.sort((a, b) => {
    if (+a.split(' ')[0] === +b.split(' ')[0]) {
      return a.split(' ')[1].localeCompare(b.split(' ')[1]);
    }
    return +b.split(' ')[0] - +a.split(' ')[0];
  });
  return sortedSeasons[0];
};

export const getUniqueValues = (
  arr: BestSellerObj[],
  key: keyof BestSellerObj | string,
  updateEventPDFs: (availableEventPDFs: string[]) => void,
  season = '',
  selectedFilters?: SelectedFilters
): string[] => {
  let filteredSeasonArray = arr;
  if (season && season !== 'All Seasons') {
    filteredSeasonArray = arr.filter((obj: BestSellerObj) => obj.OriginalBornSeason === season);
  }
  if (key === 'OriginalEvent' && selectedFilters && selectedFilters.iconCO.length > 0) {
    return [];
  }
  const uniqueFilterOptions = [
    ...new Set(
      filteredSeasonArray.map((item: any) => {
        return item[key];
      })
    )
  ];
  if (season.length !== 0) {
    updateEventPDFs(
      uniqueFilterOptions.filter((eventStr: string) => !eventStr.includes('Eyewear'))
    );
  }
  return uniqueFilterOptions;
};

export const eventFunctionDict = (data: BestSellerObj[]) => {
  const eventFunctionsMap = new Map<string, string[]>();

  data.forEach(({ OriginalEvent, Function }) => {
    if (!eventFunctionsMap.has(OriginalEvent)) {
      eventFunctionsMap.set(OriginalEvent, []);
    }
    if (!eventFunctionsMap.get(OriginalEvent)!.includes(Function)) {
      eventFunctionsMap.get(OriginalEvent)!.push(Function);
    }
  });

  return Array.from(eventFunctionsMap.entries()).reduce((acc, [OriginalEvent, Function]) => {
    return { ...acc, [OriginalEvent]: [...new Set(Function)] };
  }, {} as { [key: string]: string[] });
};

export const responseDataAdapter = (data: BestSellerObj[]) =>
  data.map((obj: BestSellerObj) => {
    if (obj?.ImageURL?.includes('/sketches') || !obj?.ImageURL?.includes('_')) {
      return {
        ...obj
      };
    } else {
      const ImageURLArray = obj.ImageURL?.split('_');
      const ImageURL = `${ImageURLArray[0]}_A.jpg`.replace('/photos/', '/photos_tiny/');

      return {
        ...obj,
        ImageURL
      };
    }
  });

export const kpisTotals = (
  bestSellerProducts: BestSellerObj[],
  eventCode: string,
  bestSellerFunction: string,
  selectedFilters: SelectedFilters
): { volume: number; salesValue: number } => {
  const kpiObj = { volume: 0, salesValue: 0 };

  bestSellerProducts.forEach((bestSeller) => {
    if (
      bestSeller.OriginalBornSeason === selectedFilters.season[0] &&
      bestSeller.EventCode === eventCode &&
      bestSeller.Function == bestSellerFunction
    ) {
      kpiObj.volume += +(bestSeller.SalesQty || 0);
      kpiObj.salesValue += +(bestSeller.SalesEuro || 0);
    }
  });

  return kpiObj;
};

export type BestSellerGrouped = {
  [key: string]: BestSellerObj[];
};

export const createKpiObject = (
  bestSellersGrouped: BestSellerGrouped,
  bestSellerProducts: BestSellerObj[],
  selectedFilters: SelectedFilters,
  kpis: any
) => {
  const kpiObj: kpiObject = {};

  Object.entries(bestSellersGrouped).map(([key, value]) => {
    const { salesValue, volume } = kpisTotals(
      bestSellerProducts,
      value[0].EventCode,
      value[0].Function,
      selectedFilters
    );
    const totalSalesForSectionValue = +kpis[key].totalSalesInEuro.toFixed(1) || 0;
    const totalOverallSalesValue = +salesValue.toFixed(1);
    const percentValueOfSection = +(
      (totalSalesForSectionValue / totalOverallSalesValue) *
      100
    ).toFixed(1);

    const totalQtyForSectionVolume = +kpis[key].totalSalesQuantity || 0;
    const totalOverallQtyVolume = +volume;
    const percentVolumeOfSectionToTotal = +(
      (totalQtyForSectionVolume / totalOverallQtyVolume) *
      100
    ).toFixed(1);

    const totalSalesInEuro = value.reduce((acc, item) => {
      return +item.SalesEuro + acc;
    }, 0);

    const totalSalesQuantity = value.reduce((acc, item) => {
      const qty = item.SalesQty || 0;
      return +qty + acc;
    }, 0);

    kpiObj[key] = {
      totalSales: +totalSalesInEuro.toFixed(1),
      totalVolume: totalSalesQuantity,
      totalSalesPercent: percentValueOfSection,
      totalVolumePercent: percentVolumeOfSectionToTotal
    };
  });

  return kpiObj;
};

export const groupSFCsByRank = (
  arr: TopFiveStoresObj[]
): Record<string, { LocationCode: string; TotalSalesQty: string }[]> => {
  const sortedData = [...arr].sort((a, b) => parseFloat(a.Rank) - parseFloat(b.Rank));

  const groupedData = sortedData.reduce(
    (
      acc: Record<
        string,
        { ConsolidatedLocationDesc: string | null; LocationCode: string; TotalSalesQty: string }[]
      >,
      item: TopFiveStoresObj
    ) => {
      const { SFC, LocationCode, TotalSalesQty, ConsolidatedLocationDesc } = item;

      if (!acc[SFC]) {
        acc[SFC] = [{ LocationCode, TotalSalesQty, ConsolidatedLocationDesc }];
      } else {
        acc[SFC].push({ LocationCode, TotalSalesQty, ConsolidatedLocationDesc });
      }

      return acc;
    },
    {}
  );

  return groupedData;
};

export const mergeBestsellerData = (topFiveStoreData: any, bestsellerData: BestSellerObj[]) => {
  return bestsellerData.map((obj: BestSellerObj) => {
    const matchingSFC = topFiveStoreData[obj.SFC];

    if (matchingSFC) {
      return {
        ...obj,
        topFiveStores: matchingSFC
      };
    } else {
      return {
        ...obj,
        topFiveStores: []
      };
    }
  });
};

export const sortSeasonsByMostRecentFirst = (dates: string[]): string[] => {
  const sortableDates = dates.map((date) => {
    const parts = date.split(' ');
    const year = parseInt(parts[0]);
    const season = parts[1];
    const seasonOrder: any = {
      Spring: 0,
      Fall: 1
    }[season];
    return year * 10 + seasonOrder;
  });
  sortableDates.sort((a, b) => b - a);
  return sortableDates.map((sortableDate) => {
    const year = Math.floor(sortableDate / 10);
    const seasonOrder = sortableDate % 10;
    const season = {
      0: 'Spring Summer',
      1: 'Fall Winter'
    }[seasonOrder];
    return `${year} ${season}`;
  });
};
