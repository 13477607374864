import { useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { connect } from 'react-redux';
import { InitialState } from './store/reducer';
import { getBestSellerProducts, getUsername, setToken, setRole } from './store/actions';
import NavBarLogo from './components/navigation/navbar';
import DrawPageContainer from './containers/drawerPageContainer';
import './scss/App.scss';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { BestSellerObj } from './helper';
import Loading from './components/Loading/Loading';

const retrieveRole = (sess: any) => {
  const role = sess?.idToken?.payload?.['custom:modela_role'];

  if (role) {
    return role;
  }
  return 'general';
};

const url = 'https://modela-bestsellers.dev.alexandermcqueen.tech';
// const url =  'https://localhost:3000';

const cognito = {
  domain: 'amq-sso',
  user_pool: 'eu-west-1_92QGqv2bj',
  user_pool_client: '7kn0b20ec0kfosg281amhggrth'
};

Amplify.configure({
  Auth: {
    oauth: {
      domain: `${cognito.domain}.auth.eu-west-1.amazoncognito.com`,
      redirectSignIn: url,
      responseType: 'code',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile']
    },
    region: 'eu-west-1',
    userPoolId: cognito.user_pool,
    userPoolWebClientId: cognito.user_pool_client
  }
});

type AppProps = {
  getBestSellers: (token: string) => void;
  setUsername: (name: string) => Dispatch<AnyAction>;
  getUser?: (token: string) => void;
  setToken: (token: string) => void;
  setRole: (token: string) => void;
  name: string;
  bestSellerProducts: BestSellerObj[];
};

const App = ({
  name,
  getBestSellers,
  setUsername,
  setToken,
  setRole,
  bestSellerProducts
}: AppProps) => {
  const [token, setCognitoToken] = useState('');

  const getCurrentSession = () => {
    return Auth.currentSession()
      .then(async (session: any) => {
        setCognitoToken(session.idToken.jwtToken);
        setRole(retrieveRole(session));
        const { attributes } = await Auth.currentAuthenticatedUser();
        setUsername(`${attributes.given_name} ${attributes.family_name}`);
      })
      .catch((err) => {
        console.log('current session failed');
        console.log(err);
        Auth.federatedSignIn({ customProvider: 'kering-sso' });
        return err;
      });
  };

  const getUser = async () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log('Not signed in'));
  };

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('event', event);
      switch (event) {
        case 'signIn':
          console.log('signIn');
          break;
        case 'cognitoHostedUI':
          setToken('grating...');
          console.log('grating...');
          break;
        case 'signOut':
          setToken('');
          break;
        case 'signIn_failure':
          console.log('signIn_failure', data);
          break;
        case 'cognitoHostedUI_failure':
          console.log('cognitoHostedUI_failure', data);
          break;
        default:
          break;
      }
    });

    getCurrentSession();
  }, []);

  useEffect(() => {
    if (name.length && token.length) {
      getBestSellers(token);
    }
  }, [token]);

  return (
    <div>
      <NavBarLogo />
      {bestSellerProducts.length === 0 ? <Loading /> : <DrawPageContainer />}
    </div>
  );
};

const mapStateToProps = (state: InitialState) => ({
  name: state.name,
  bestSellerType: state.bestSellerType,
  bestSellerProducts: state.bestSellerProducts
});

const mapDispatchToProps = (dispatch: any) => ({
  setUsername: (name: string) => dispatch(getUsername(name)),
  getBestSellers: (token: string) => dispatch(getBestSellerProducts(token)),
  setToken: (token: string) => dispatch(setToken(token)),
  setRole: (role: string) => dispatch(setRole(role))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
