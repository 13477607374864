import axios from 'axios';

const API = 'https://dev.amq-modela-bestsellers.shared.dev4apps.io/modela/bestseller';
// const API = 'http://localhost:4005/dev/modela/bestseller';

axios.defaults.baseURL = API;

const getBestSellers = (token: string, originalBornSeason: string) =>
  axios.get(`/getBestSellers?originalBornSeason=${originalBornSeason}`, {
    headers: {
      Authorization: token
    }
  });

const getTopFiveStores = (token: string) =>
  axios.get(`/getTopFiveStores`, {
    headers: {
      Authorization: token
    }
  });

const getSeasons = (token: string) =>
  axios.get(`/getSeasons`, {
    headers: {
      Authorization: token
    }
  });

const getBestSellerPdf = async (seasonCode: string, eventCode: string, requestBody: any) =>
  axios.post(`/generateBestsellerPdf?seasonCode=${seasonCode}&eventCode=${eventCode}`, requestBody);

export default {
  getBestSellers,
  getBestSellerPdf,
  getTopFiveStores,
  getSeasons
};
