
export const bestSellerPdfDict = (bestSellerPdfLinks) => [
  {
    name: '',
    seasonCode: '',
    OriginalBornSeason: '',
    season: 'Women Pre',
    OriginalEvent: 'W Pre',
    eventCode: 'N1',
    link: bestSellerPdfLinks['Women Pre']
  },
  {
    name: '',
    seasonCode: '',
    OriginalBornSeason: '',
    season: 'Men Early',
    OriginalEvent: 'M Early',
    eventCode: 'N3',
    link: bestSellerPdfLinks['Men Early']
  },
  {
    name: '',
    seasonCode: '',
    OriginalBornSeason: '',
    season: 'Women Early',
    OriginalEvent: 'W Early',
    eventCode: 'N0',
    link: bestSellerPdfLinks['Women Early']
  },
  {
    name: '',
    seasonCode: '',
    OriginalBornSeason: '',
    season: 'Women Main',
    OriginalEvent: 'W Main',
    eventCode: 'N2',
    link: bestSellerPdfLinks['Women Main']
  },
  {
    name: '',
    seasonCode: '',
    OriginalBornSeason: '',
    season: 'Men Main',
    OriginalEvent: 'M Main',
    eventCode: 'N4',
    link: bestSellerPdfLinks['Men Main']
  }
];
